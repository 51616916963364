import request from '@/utils/request';

export function getUserMenuList(data) {
    return request({
        url: '/user_menu/list',
        method: 'post',
        data: data
    });
}

export function getUserMenuInfo(id) {
    return request({
        url: '/user_menu/info/' + id,
        method: 'get',
    });
}

export function delUserMenu(data) {
    return request({
        url: '/user_menu/del',
        method: 'post',
        data: data
    });
}
