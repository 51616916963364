<template>
    <div>
        <Breadcrumb></Breadcrumb>
        <div class="header">
            <el-row>
                <div class="form">
                    <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                        <el-form-item label="名称">
                            <el-input v-model="searchForm.name" placeholder="名称" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="用户">
                            <el-select v-model="searchForm.user_id" filterable clearable placeholder="用户" size="small">
                                <el-option v-for="user in checkUser" :key="user.id" :label="user.user_name" :value="user.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small" icon="el-icon-search" @click="getUserMenuList">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-row>
            <el-row>
                <el-col :span="22">
                    <el-button type="danger" size="small" @click="delUserMenu()">批量删除</el-button>
                </el-col>
                <el-col :span="2"></el-col>
            </el-row>
        </div>
        <div class="content">
            <el-table ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange" :default-sort="{ prop: 'id', order: 'ascending' }">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column prop="intro" label="简介"></el-table-column>
                <el-table-column prop="user_id" label="用户名" :formatter="userIdFormatter"></el-table-column>
                <el-table-column prop="create_time" label="创建时间" sortable></el-table-column>
                <el-table-column label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="showForm(scope.row.id)">详情</el-button>
                        <el-button type="danger" size="mini" @click="delUserMenu(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <Pagination :total="total" :perPage="perPage" :currentPage="currentPage" @currentPageChange="currentPageChange"></Pagination>
            </div>
        </div>
        <ViewForm v-if="viewFormDialog" :viewFormDialog="viewFormDialog" :viewFormId="viewFormId" @closeForm="closeForm" @getUserMenuList="getUserMenuList"></ViewForm>
    </div>
</template>

<script>
import { getUserMenuList, delUserMenu } from '@/utils/servers/userMenu.js';
import { getCheckUser } from '@/utils/servers/user.js';
import Pagination from '@/components/Pagination/Pagination.vue';
import ViewForm from './view.vue';
export default {
    components: {
        Pagination,
        ViewForm
    },
    computed: {
        userIdFormatter() {
            return (row, column, value) => {
                let arr = this.checkUser.find(val => val.id === value);
                if (arr) {
                    return arr.user_name;
                } else {
                    return value;
                }
            };
        }
    },
    data() {
        return {
            searchForm: {
                name: '',
                user_id: ''
            },
            list: [],
            total: 0,
            perPage: 0,
            currentPage: 0,
            checkIds: [],
            checkUser: [],
            viewFormDialog: false,
            viewFormId: 0
        };
    },
    created() {
        this.getCheckUser();
        this.getUserMenuList();
    },
    methods: {
        handleSelectionChange(val) {
            this.checkIds = val ? val.map(item => item.id) : [];
        },
        currentPageChange(val) {
            this.currentPage = val;
            this.getUserMenuList();
        },
        getUserMenuList() {
            getUserMenuList({ searchForm: this.searchForm, perPage: this.perPage, currentPage: this.currentPage }).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.list = data.data.data;
                    this.total = data.data.total;
                    this.perPage = data.data.per_page;
                    this.currentPage = data.data.current_page;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        getCheckUser() {
            getCheckUser().then(res => {
                var data = res;
                if (data.code == 1) {
                    this.checkUser = data.data.list;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        delUserMenu(id) {
            var ids = id ? id : this.checkIds.toString();
            this.$confirm('此操作将永久删除数据，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delUserMenu({ ids: ids }).then(res => {
                    var data = res;
                    if (data.code == 1) {
                        this.$message.success(data.msg);

                        if (this.total % this.perPage === 1) {
                            this.currentPage = this.currentPage - 1 > 0 ? this.currentPage - 1 : 1;
                        }

                        this.getUserMenuList();
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            });
        },
        showForm(id = 0) {
            this.viewFormDialog = true;
            this.viewFormId = id;
        },
        closeForm() {
            this.viewFormDialog = false;
        }
    }
};
</script>

<style lang="less" scoped>
.header {
    background: #fff;
    margin-bottom: 20px;
}
.content {
    .pagination {
        padding: 10px;
    }
}
</style>
